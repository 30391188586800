import React from "react";
import {Link} from "gatsby"
import Layout from "../../../../../components/layout"
import JobsTable from "../../../../../components/JobsTable";
import z4Logo from "../../../../../images/north.svg"
import HeaderFR from "../../../../../components/HeaderFR";
import FooterFR from "../../../../../components/FooterFR";

const columns = [
    {id: 'job', label: 'Postes', minWidth: 170, link: "link"},
];

function createData(job, link) {
    return {job, link};
}


const rows = [
        createData('Infirmière diplômée', 'https://gnb1.hiringplatform.ca/24372-registered-nurse/76442-registration/fr_CA'),
        createData('Infirmière auxiliaire autorisée', 'https://gnb1.hiringplatform.ca/24373-licensed-practical-nurse/76445-registration/fr_CA'),
        createData('Infirmière praticienne', 'https://gnb1.hiringplatform.ca/24374-nurse-practitioner/76448-registration/fr_CA'),
        createData('Pharmacien', 'https://gnb1.hiringplatform.ca/24375-pharmacist/76451-registration/fr_CA'),
        createData('Technicien en pharmacie', 'https://gnb1.hiringplatform.ca/24376-pharmacy-technician/76454-registration/fr_CA'),
        createData('Technologue en imagerie diagnostique', 'https://gnb1.hiringplatform.ca/24377-diagnostic-imaging-technologist/76457-registration/fr_CA'),
        createData('Assistant administratif        ', 'https://gnb1.hiringplatform.ca/24378-administrative-assistant/76460-registration/fr_CA'),
        createData('Travailleur social', 'https://gnb1.hiringplatform.ca/24379-social-worker/76463-registration/fr_CA'),
        createData('Psychologue', 'https://gnb1.hiringplatform.ca/24380-psychologist/76466-registration/fr_CA'),
        createData('Préposé aux soins aux patients', 'https://gnb1.hiringplatform.ca/24381-patient-care-attendant/76469-registration/fr_CA'),
        createData('Travailleur de soutien personnel', 'https://gnb1.hiringplatform.ca/24382-personal-support-worker/76472-registration/fr_CA'),
        createData('Gestionnaires de soins de santé', 'https://gnb1.hiringplatform.ca/24385-healthcare-managers/76481-registration/fr_CA'),
        createData('Professionnels de l\'administration des soins de santé', 'https://gnb1.hiringplatform.ca/24384-healthcare-administrative-professionals/76478-registration/fr_CA'),
        createData('Thérapeute respiratoire', 'https://gnb1.hiringplatform.ca/24386-respiratory-therapist/76484-registration/fr_CA'),
        createData('Ergothérapeutes', 'https://gnb1.hiringplatform.ca/24387-occupational-therapists/76487-registration/fr_CA'),
        createData('Physiothérapeute', 'https://gnb1.hiringplatform.ca/24388-physiotherapist/76490-registration/fr_CA'),
        createData('Aide en réadaptation', 'https://gnb1.hiringplatform.ca/24389-rehabilitation-assistant/76493-registration/fr_CA'),
        createData('Orthophoniste', 'https://gnb1.hiringplatform.ca/23986-speech-language-pathologist/75168-registration/fr_CA'),

    ]
;
const index = () => (
    <Layout>
        <HeaderFR title={"Bienvenue au connecteur d'emploi dans les soins de santé du Nouveau-Brunswick"}/>
        <div className={"container-xxl tac-lg"}>
            <Link to="/fr/sante/">
                <button className={"mb3-lg"}>Retour à la carte complète</button>
            </Link>
            <div className={"c jc ac"}>
                <h2 className={"py4"}>Postes ouverts</h2>
            </div>
        </div>
        <div className={"container-xxl r-c-lg mt5 mt0-lg jc"}>
            <div className={"c w50 jfs ac w100-lg"}>
                <img className={"m3"} width="400" height="300" src={z4Logo} alt="North NB Area"/>
            </div>
            <div className={"c w50 jc ac w100-lg"}>
                <JobsTable rows={rows} columns={columns}/>
            </div>
        </div>
        <FooterFR/>

    </Layout>
);

export default index;